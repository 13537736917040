import React, { useState } from "react"

import { getSession } from "../../services/auth"
import Alert from "react-bootstrap/Alert"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Badge from "react-bootstrap/Badge"
import ListGroup from "react-bootstrap/ListGroup"

import Banner from "./banner"

import css from "./pricing.module.css"
import { Link } from "../../services/routes" // "gatsby"
import routes from "../../services/routes"

const plans = [
	{
		planName: "Free",
		perks: [
			"Unlimited Boards",
			"Limited Sharing",
			"100 MB Storage",
			" ",
			" ",
			" ",
		],
		monthlyPriceAnnually: 0,
		monthlyPriceMonthly: 0,
	},
	{
		planName: "Standard",
		perks: [
			"(Under development)",
			"Unlimited Boards",
			"Join Groups",
			"Standard Sharing",
			"500 MB Storage",
			" ",
		],
		monthlyPriceAnnually: 4,
		monthlyPriceMonthly: 5,
	},
	{
		planName: "Premium",
		perks: [
			"(Under development)",
			"Unlimited Boards",
			"Join Groups",
			"Manage Groups",
			"Unlimited Sharing",
			"5 GB Storage",
		],
		monthlyPriceAnnually: 8,
		monthlyPriceMonthly: 10,
	}
];

const Plan = ({planData: {planName, perks, monthlyPriceAnnually, monthlyPriceMonthly}, billingMethod}) => {
	const session = getSession();
	return (
	<div className="col-lg-4">
		<div className="price_item">
			<div className="price_head">
				<h4>{planName}</h4>
			</div>
			<div className="price_body">
				<ul className="list">
					{
						perks.map((perk, index) => (
							<li key={index}>
                                {perk}&nbsp;
							</li>
						))
					}
				</ul>
			</div>
			<div className="price_footer">
				<h3 style={{marginBottom:0}}>
					<span className="dlr">$</span>
					{
						billingMethod=="annually" ?
						<span>{monthlyPriceAnnually}{' '}</span> :
						<span>{monthlyPriceMonthly}{' '}</span>
					}
					<span className="month">
						Per
						<br />
						Month
					</span>
				</h3>
				<p style={{visibility:monthlyPriceMonthly==0?"hidden":"visible"}}>
					billed {billingMethod}
				</p>
				{ (session.user === null) &&
					<Link className="main_btn"
						to="/signup?plan=premium"
						style={{visibility:monthlyPriceMonthly==0?"hidden":"visible"}}
					>
						Get Started
					</Link>
				}
				{ (session.user !== null) &&
					<Link className="main_btn" 
						style={{visibility:monthlyPriceMonthly==0?"hidden":"visible"}}
						to="/pricing"
					>
						Upgrade
					</Link>
				}
			</div>
		</div>
	</div>
	);
};

const PlanList = ({billingMethod}) => (
	<div className="price_inner row">
		{
			plans.map((planData, index) => (
				<Plan planData={planData} billingMethod={billingMethod} key={index}/>
			))
		}
	</div>
);

const BillingMethodSwitch = ({billingMethod, setBillingMethod}) => (
	<span style={{margin:"0 auto"}}>
		<h4 style={{display:"inline"}}>monthly </h4>
		<label className={css.switch} style={{verticalAlign:"middle", marginBottom:0}}>
			<input id="billing_input" type="checkbox" checked={billingMethod=="annually"}
				onChange={event => setBillingMethod(event.target.checked ? "annually" : "monthly")}
			/>
			<span className={`${css.slider} ${css.round}`}></span>
		</label>
		<h4 style={{display:"inline"}}> annually</h4>
	</span>
);

const theme_styling = {
	color: "white",
	background: "#3bacf0",
}

const PricingContent = () => {
	const [billingMethod, setBillingMethod] = useState("annually");
	return (
		<section className="price_area">
			<div style={{margin:"50px auto", padding:"15px", textAlign:"justify", maxWidth:"700px"}}>
				<Alert variant='success' >
					<p>
						In the age of COVID-19, we are here to help!
						We have received many inquiries from high schools and universities and have developed a simpler pricing model specifically to accommodate them.
					</p>
					<p>
						To best help educators during this unprecedented time, we have temporarily made almost all features available for free.
						Over time, we will transition to the pricing model described below.
						Users on the <b>Free Beta</b> plan may experience interruptions due to these changes.
						Users on the <b>Paid Beta</b> plan will not be affected. We strongly encourage educators to consider the <b>Paid Beta</b> plan for this reason.
					</p>
					<p>
						We offer discounts to individual instructors who pay out-of-pocket because their school or department is not yet ready to purchase a license on their behalf.
						To request a discount, please fill out <Link to={routes.forms.discount}>this form</Link>.
					</p>
				</Alert>
				{/*
				<p>
					If you or your department or school would like to join the 
					Paid Beta, please fill out <Link to={routes.forms.beta}>this form</Link>.
				</p>
				*/}
				<Row>
					<Col style={{marginTop:"20px"}}>
						<h2>Free Beta</h2>
						<span>For occasional users</span>
						<ListGroup>
							<ListGroup.Item style={{fontSize:"2em", ...theme_styling}}>$0</ListGroup.Item>
							<ListGroup.Item>Create and share boards</ListGroup.Item>
							<ListGroup.Item>Up to 2 simultaneous users per board</ListGroup.Item>
							<ListGroup.Item>Writing text, freehand drawing, and adding shapes</ListGroup.Item>
							<ListGroup.Item>Scan drawings from a camera</ListGroup.Item>
							<ListGroup.Item>Annotate PDFs</ListGroup.Item>
						</ListGroup>
					</Col>
					<Col style={{marginTop:"20px"}}>
						<h2>Paid Beta <Badge variant='primary' style={theme_styling}>New!</Badge></h2>
						<span>For instructors, departments, and schools</span>
						<ListGroup>
							<ListGroup.Item style={theme_styling}>
								<span style={{fontSize:"2em"}}>$1</span>/user/month (including students)
							</ListGroup.Item>
							<ListGroup.Item>All free features</ListGroup.Item>
							<ListGroup.Item>
								Up to 7 simultaneous users per board
								<br />
								<span style={{fontSize:".8em"}}>(We're working to increase this number.)</span>
							</ListGroup.Item>
							<ListGroup.Item>Copy boards</ListGroup.Item>
							<ListGroup.Item>Type in LaTeX</ListGroup.Item>
							<ListGroup.Item>Print and export as PDF</ListGroup.Item>
							<ListGroup.Item>Ad free</ListGroup.Item>
							<ListGroup.Item variant="light">
								Planned features
								<ul className={css.pricing}>
									<li>Full class on one board</li>
									<li>Break-out boards</li>
									<li>LaTeX pen</li>
									<li>Teacher/Admin dashboard</li>
									<li>Integration with LMS</li>
								</ul>
								<span style={{fontSize:".8em"}}>
									(These features are not available yet.)
								</span>
							</ListGroup.Item>
							<ListGroup.Item style={{textAlign:"center"}}>
								<Link className="main_btn" to={routes.forms.beta}>Request to Join</Link>
							</ListGroup.Item>
						</ListGroup>
					</Col>
				</Row>
				<p style={{textAlign:"center", marginTop:"50px"}}>
					Questions? See our{' '}
					<Link to={routes.faq}>FAQ page</Link>
					{' '}or reach out to us at{' '}
					<a href="mailto:contact@scratchwork.io">contact@scratchwork.io</a>
				</p>
			</div>
		</section>
	);
}

export default () => (
	<>
		<Banner title="Pricing" link={routes.pricing} />
		<PricingContent />
	</>
);
